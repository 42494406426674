import React, { useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import PrimaryButton from "../../../components/includes/button/PrimaryButton";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import left from "../../../assets/images/icons/left.png";
import right from "../../../assets/images/icons/right.png";

export default function Scroll({ data }) {
  const sliderRef = useRef(null); // Create a ref for the Slider component

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Change this to adjust autoplay speed
    pauseOnHover: false,
    fade: true,
    cssEase: "linear",
  };

  const goToPrevious = () => {
    sliderRef.current.slickPrev(); // Call slickPrev method on Slider component
  };

  const goToNext = () => {
    sliderRef.current.slickNext(); // Call slickNext method on Slider component
  };

  return (
    <MainContainer>
      <Slider {...settings} ref={sliderRef}>
        {data.map((item, index) => (
          <BackgroundSlide key={index} src={item.image}>
            <ContentBox>
              <Paragraph >{item.paragraph}</Paragraph>
              <Title>{item.title}</Title>
              <PrimaryButton text="know more" to={item.link()} />
            </ContentBox>
          </BackgroundSlide>
        ))}
      </Slider>

      <PreviousButton onClick={goToPrevious}>
        <Icon src={left} />
      </PreviousButton>
      <NextButton onClick={goToNext}>
        <Icon src={right} />
      </NextButton>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  /* height: 100%; */
  /* height: calc(100vh - 100px); */
  overflow: hidden;
  z-index: 10;
  position: relative; /* Ensure position context for absolute positioning of buttons */
`;

const BackgroundSlide = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.5);
  mix-blend-mode: overlay;
  /* filter: brightness(70%); */
  box-sizing: border-box;
  /* Safari-specific adjustments */
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    position: -webkit-sticky;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  @media all and (max-width: 1080px) {
    height: 84vh;
  }
  @media all and (max-width: 980px) {
    height: 70vh;
  }
  @media all and (max-width: 768px) {
    height: 50vh;
  }
  @media all and (max-width: 640px) {
    height: 42vh;
  }
  @media all and (max-width: 530px) {
    height: 35vh;
  }
  @media all and (max-width: 430px) {
    height: 34vh;
  }
`;

const ContentBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  overflow: hidden;


  /* @media all and (max-width: 640px) {
        top: 25%;
    }
    @media all and (max-width: 480px) {
        top: 13%;
    } */
`;

const Title = styled.h2`
  color: white;
  font-size: 40px;
  margin-bottom: 16px;
  font-family: "kenexy";  

  @media all and (max-width: 640px) {
    font-size: 28px;
  }
  @media all and (max-width: 480px) {
    font-size: 18px;
  }
  @media all and (max-width: 430px) {
    font-size: 15px;
  }
`;

const Paragraph = styled.p`
  z-index: 100;
  color: white;
  font-size: 34px;
  font-family: "kenexy";
  text-transform: uppercase;
  margin-bottom: 16px;

    background: rgba(255, 255, 255, 0.23);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 20px;
  @media all and (max-width: 640px) {
    font-size: 28px;
  }
  @media all and (max-width: 480px) {
    font-size: 24px;
  }
  @media all and (max-width: 430px) {
    font-size: 18px;
  }
`;

const PreviousButton = styled.button`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 999;
  display: inline-block;
  cursor: pointer;
`;

const NextButton = styled.button`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 999;
  display: inline-block;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  display: block;
  object-fit: cover;
  &:hover {
    filter: invert(1);
  }
  @media all and (max-width: 640px) {
    width: 20px;
    height: 20px;
  }
`;
