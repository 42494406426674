import React from 'react'
import styled from 'styled-components';

import nihal from '../../../assets/images/CEO.png'
import coo from '../../../assets/images/co-founder.png'
import { DivIcon } from 'leaflet';
export default function Message() {
    return (
        <>
            <MainContainer>
                <MessageContainer>
                    <div className='wrapper'>

                        <Ul>
                            <Li>
                                <Left>
                                    <ImgContainer>
                                        <Img src={nihal} alt="Image" /></ImgContainer>
                                </Left>
                                <Right>
                                    <Content>
                                        <Messages>“Vektas Technologies, founded on the principles of innovation and versatility, is at the forefront of redefining the technological landscape. Specializing in drone technology and its innovative spin-offs, we are committed to delivering quality, reliability, and end-to-end solutions across diverse industries“</Messages>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                            <div style={{ marginRight: '20px', width: '60px', height: '60px', }}><SecondaryImage src={nihal} alt="Image" /></div>

                                            <Last>
                                                <Name>NIHAL AHMMED</Name>
                                                <Position>FOUNDER, C.E.O</Position>
                                            </Last>
                                        </div>
                                    </Content>
                                </Right>
                            </Li>
                        </Ul>
                    </div>
                </MessageContainer>

                <MessageContainer>
                    <div className='wrapper'>
                        <Ul>
                            <CooLi>
                                <Right>
                                    <Content>
                                        <Messages>“At Vektas, we don’t just build technologies; we craft solutions tailored to meet the unique challenges of each industry we serve. Join us on this journey as we continue to soar to new heights, providing clients with the tools and services to embrace a future where possibilities are limitless“</Messages>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                            <div style={{ marginRight: '20px', width: '60px', height: '60px' }}>
                                                <SecondaryImage src={coo} alt="Image" />
                                            </div>


                                            <Last>
                                                <Name>HAYAT MUSTAFA</Name>
                                                <Position> CO-FOUNDER, C.O.O</Position>
                                            </Last>
                                        </div>
                                    </Content>
                                </Right>
                                <Left style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <ImgContainer>
                                        <Img src={coo} alt="Image" /></ImgContainer>
                                </Left>
                            </CooLi>
                        </Ul>
                    </div>
                </MessageContainer>
            </MainContainer>
        </>
    )
}

const MainContainer = styled.div`
    padding: 150px 0;
    @media all and (max-width: 1180px) {
    padding: 120px 0;
        
    }
    @media all and (max-width: 1180px) {
    padding: 80px 0;
        
    }
    @media all and (max-width: 768px) {
    padding: 30px 0;
        
    }
    @media all and (max-width: 640px) {
    padding: 1px 0 80px;
        
    }
    @media all and (max-width: 480px) {
    padding: 1px 0 20px;
        
    }
`;

const MessageContainer = styled.div`
    background: linear-gradient(rgba(47, 83, 164, 1), rgba(18, 19, 62, 1));
   margin-top: 200px;
   &:first-child{
       margin-top: 0;
   }
   @media all and (max-width: 1180px) {
    margin-top: 140px;
    }
    @media all and (max-width: 1180px) {
    margin-top: 30px;
    }
    @media all and (max-width: 640px) {
    margin-top: 40px;
    }
`;

const Ul = styled.ul`
  
`;

const Li = styled.li`
    padding: 40px 0px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: flex-start;
    grid-gap: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    @media all and (max-width: 640px) {
        grid-template-columns: 1fr;
    }
    @media all and (max-width: 640px) {
    padding: 1px 0px;
    }

`;
const CooLi = styled.div`
    padding: 40px 0px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    justify-content: flex-start;
    grid-gap: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    @media all and (max-width: 640px) {
        grid-template-columns: 1fr;
    }
    @media all and (max-width: 640px) {
    padding: 1px 0px;
    }
`;


const ImgContainer = styled.div`
    width: 300px;
    overflow: hidden;
    position: absolute;
    top: -32px;
    @media all and (max-width: 1180px) {
        top: -46px;
    }
    @media all and (max-width: 980px) {
        width: 200px;
        top: 46px;
    }
    @media all and (max-width: 640px) {
     display: none;
    }
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
`;
const Left = styled.div`
`;
const Right = styled.div`
    padding: 40px 0;
`;

const Content = styled.div`
`;

const Messages = styled.p`
    font-size: 20px;
    color: #fff;
    font-family: 'montserrat';
    text-align: justify;
    @media all and (max-width: 1180px) {
        font-size: 16px;
    }
     @media all and (max-width: 480px) {
        margin-bottom: 20px;
    }
      @media all and (max-width: 430px) {
        font-size: 14px;
    }
`;

const Last = styled.div`
    margin-top: 20px;
    display: grid;
    align-items: flex-end;
    justify-content: flex-end;
`;
const SecondaryImage = styled.img`
   width: 100%;
   height: 100%;
    object-fit: contain;
    display: block;
    display: none;
    @media all and (max-width: 640px) {
        display: block;
    }
`;


const Name = styled.h5`
    font-size: 20px;
    color: #fff;
    font-family: 'montserrat';
    font-weight: 600;
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
`;

const Position = styled.p`
    font-size: 18px;
    color: #fff;
    font-family: 'montserrat';
     @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;