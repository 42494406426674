import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'

export default function GetMe() {

    const [activeNavItem, setActiveNavItem] = useState(null);
    const [isMenu, setMenu] = useState(false);

    const handleNavItemClicked = (index) => {
        setActiveNavItem(index);
        window.scrollTo(0, 0);
        setMenu(false);
    };

    useEffect(() => {
        const pathname = window.location.pathname;
        const navItems = ['/', '/explore', '/product', '/service', '/design', '/contact']; // Add your navigation item paths here
        const activeIndex = navItems.indexOf(pathname);
        setActiveNavItem(activeIndex !== -1 ? activeIndex : null);
        setMenu(false);

    }, []);


    return (
        <>
            <MainContainer>
                <div className='wrapper'>
                    <Top>
                        {/* <P>Have a project in mind or need assistance with our drone services ?</P> */}
                        <P>Click below to submit your enquiry now and let's take your ideas to new heights together</P>
                        <Bottom>
                            <Button to="/contact" onClick={() => handleNavItemClicked(5)} active={activeNavItem === 5}>ENQUIRY</Button>
                        </Bottom>
                    </Top>
                </div>
            </MainContainer>
        </>
    )
}

const MainContainer = styled.div`
    padding: 50px 0;
    background: linear-gradient(
        135deg,
        rgba(47, 83, 164, 1),
        rgba(18, 19, 62, 1)
    );
    position: relative;
    z-index: 100;
`;

const Top = styled.div`
    text-align: center;
`;

const P = styled.p`
    font-size: 20px;
    font-family: 'montserrat';
    color: #fff;
    @media all and (max-width: 640px) {
        font-size: 16px;
    }
     @media all and (max-width: 480px) {
        font-size: 15px;
    }
`;

const Bottom = styled.div`
    text-align: center;
    margin-top: 20px;
`;

const Button = styled(Link)`
    color: #000;
    display: inline-block;
    padding: 17px 18px;
   background: #fff;
    clip-path: polygon(
        15px 0,
        calc(100% - 0px) 0,
        100% 15px,
        100% calc(100% - 15px),
        calc(100% - 15px) 100%,
        15px 100%,
        0 calc(100% - 0px),
        0 15px
    );
    background-repeat: no-repeat;
    font-size: 20px;
    text-transform: uppercase;
    font-family: "kenexy";
    cursor: pointer;
    transition: all 0.5s ease; 
    text-decoration: none; 
 
    @media all and (max-width: 1280px) {
        font-size: 16px;
    }
    @media all and (max-width: 980px) {
        font-size: 12px;
        padding: 14px 16px;
    }
`;