import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../../assets/images/icons/logo.png';
import menu from '../../../assets/images/icons/menu.png';
import down from "../../../assets/images/icons/drop-down.png";

export default function Header() {
    const [isMenu, setMenu] = useState(false);
    const location = useLocation();


    const handleLinkClick = (id) => {
        window.scrollTo(0, 0);
        const targetElement = document.getElementById(id.toLowerCase());
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
        setMenu(false);
    };

    // Accordian Menu 

    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    useEffect(() => {
        setMenu(false);
        window.scrollTo(0, 0);

    }, [location]);

    return (
        <>
            <Container>
                <MainContainer>
                    <SubContainer className='wrapper'>
                        <Left>
                            <H1>
                                <AContainer to="/" >
                                    <LogoContainer>
                                        <Logo src={logo} alt='Logo' />
                                    </LogoContainer>
                                </AContainer>
                            </H1>
                        </Left>
                        <Right>
                            <Ul>
                                <LI>
                                    <StyledLink to="/">Home</StyledLink>
                                </LI>
                                <LI>
                                    <StyledLink to="/explore" >Explore US</StyledLink>
                                </LI>
                                <LI>
                                    <StyledLink>Products <DropDown src={down} />
                                        <SubNav >
                                            <SubNavItem to="/product/vtol-drone">VTOL Drone</SubNavItem>
                                            <SubNavItem to="/product/swarm">Swarm</SubNavItem>
                                            <SubNavItem to="/product/disaster-management">DISASTER MANAGEMENT</SubNavItem>
                                            <SubNavItem to="/product/propellers">PROPELLERS</SubNavItem>
                                            <SubNavItem to="/product/wind-tunnel">Wind Tunnel</SubNavItem>
                                        </SubNav>
                                    </StyledLink>
                                </LI>
                                <LI>
                                    <StyledLink to="/service" >Services</StyledLink>
                                </LI>
                                <LI>
                                    <StyledLink to="/design" >Design consultations</StyledLink>
                                </LI>
                                <LI>
                                    <StyledLink to="/contact" >Contact us</StyledLink>
                                </LI>
                            </Ul>
                            <MenuIcon onClick={() => setMenu(!isMenu)}  >
                                <MenuImG
                                    src={menu}
                                    alt="menu"
                                />
                            </MenuIcon>
                        </Right>
                    </SubContainer>
                    <DropdownMenu isMenu={isMenu}>
                        <DropdownItem to="/">Home</DropdownItem>
                        <DropdownItem to="/explore" onClick={() => handleLinkClick('explore')}>Explore us</DropdownItem>

                        <AccordionItem>
                            <AccordionHeader onClick={() => toggleAccordion(0)}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <P>Products <DropDown style={{ filter: "invert(1)" }} src={down} /></P>
                                </div>
                            </AccordionHeader>
                            <AccordionContent isOpen={openIndex === 0}>
                                <DropdownItem style={{ fontSize: "15px" }} to="/product/vtol-drone" onClick={() => handleLinkClick('product')}>VTOL Drone</DropdownItem>
                                <DropdownItem style={{ fontSize: "15px" }} to="/product/swarm" onClick={() => handleLinkClick('product')}>Swarm</DropdownItem>
                                <DropdownItem style={{ fontSize: "15px" }} to="/product/disaster-management" onClick={() => handleLinkClick('product')}>Disaster Management</DropdownItem>
                                <DropdownItem style={{ fontSize: "15px" }} to="/product/propellers" onClick={() => handleLinkClick('product')}>Propellers</DropdownItem>
                                <DropdownItem style={{ fontSize: "15px" }} to="/product/wind-tunnel" onClick={() => handleLinkClick('product')}>Wind Tunnel</DropdownItem>
                            </AccordionContent>
                        </AccordionItem>

                        <DropdownItem to="/service" onClick={() => handleLinkClick('service')}>Services</DropdownItem>
                        <DropdownItem to="/design" onClick={() => handleLinkClick('design')}>Design Consultations</DropdownItem>
                        <DropdownItem to="/contact" onClick={() => handleLinkClick('contact')}>Contact us</DropdownItem>
                    </DropdownMenu>

                </MainContainer>
            </Container >
            <Overlay isMenu={isMenu} onClick={() => setMenu(false)} />
        </>
    );
}

const Container = styled.div`
    position: relative;
    width: 100%;
    z-index: 100000;
`;

const MainContainer = styled.div`
    background-color: #fff;
    width: 100%;
    position: fixed;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: ${(props) => (props.isMenu ? 'block' : 'none')};
`;

const SubContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Left = styled.div``;

const H1 = styled.h1``;

const AContainer = styled(Link)`
    cursor: pointer;
`;

const LogoContainer = styled.div`
  width: 100px;
  display: block;
  @media all and (max-width: 480px) {
    width: 74px;
  }
    @media all and (max-width: 430px) {
    width: 72px;
  }
`;

const Logo = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
`;

const Right = styled.div``;

const Ul = styled.ul`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 1280px) {
        display: none;
    }
`;

const LI = styled.li`
    cursor: pointer;
`;

const DropDown = styled.img`
    display: block;
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-left: 5px;
    transition: transform 0.3s ease; /* Added smooth rotating transition */
`;

const SubNav = styled.div`
    width: max-content;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 100;
    display: none;
    border-radius: 8px;
`;

const SubNavItem = styled(Link)`
    display: block;
    padding: 10px;
    font-size: 18px;
    font-family: "kenexy";
    cursor: pointer;
    color: #000;
    &:hover {
        color: #2F53A4;
    }
`;

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    text-transform: uppercase;
    margin-left: 40px;
    font-family: "kenexy";
    position: relative;
    &:hover {
        & > ${DropDown} {
            transform: rotate(180deg);
        }
        & > ${SubNav} {
            display: block;
        }
    }
    
    ${(props) =>
        props.to === window.location.pathname && // Compare the current location
        css`
            color: #2F53A4; // Apply active color
        `}
    &:hover {
        color: #2F53A4; // Apply hover color
    }
`;

const MenuIcon = styled.div`
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: none;
    @media all and (max-width: 1280px) {
        display: block;
    }
     @media all and (max-width: 480px) {
         width: 24px;
    height: 24px;
    }
`;

const MenuImG = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 100%;
    padding: 40px;
    margin: 0;
    z-index: 100;
    overflow: hidden;
    transition: right 0.3s ease; /* Added transition for left position */
    right: ${(props) => (props.isMenu ? '0' : '-110%')}; /* Initial position */
    background-color: #000;
    /* width: max-content; */
    width: 100%;
    height: 100vh;
`;

const DropdownItem = styled(Link)`
    display: flex;
    align-items: center;
    padding: 10px 2px;
    font-size: 18px;
    color: #fff;
    font-family: "kenexy";
    cursor: pointer;
    text-transform: capitalize;

      ${(props) =>
        props.to === window.location.pathname && // Compare the current location
        css`
            color: #2F53A4; // Apply active color
        `}
`;


// Accordian Styles 
const AccordionItem = styled.div`

`;

const AccordionHeader = styled.div`
    cursor: pointer;
`;

const P = styled.p`
    display: flex;
    align-items: center;
    padding: 10px 2px;
    font-size: 18px;
    color: #fff;
    font-family: "kenexy";
    cursor: pointer;
    text-transform: capitalize;
`;

const AccordionContent = styled.div`
    margin-left: 12px;

    max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
    overflow: hidden;
    transition: max-height 0.3s padding 0.3s ease-in-out 0s;
`;