import React from 'react';
import Banner from '../../includes/products/Banner';
import Details from '../../includes/products/Details';
import Features from '../../includes/products/Features';
import Application from '../../includes/products/Application';
import GetMe from '../GetMe/GetMe';

export default function Propellers() {
    const data = {
        image: require("../../../assets/images/disaster-mgt.jpg"),
        heading: "DISASTER MANAGEMENT",
        paragraph: "In disaster management, Vektas FRD addresses the critical need for rapid, efficient, and precise response. Its applications span rescue operations, supply delivery, and surveillance."
    };

    const details = {
        tittle: "OVERVIEW",
        paragraph: "Vektas Technologies presents a cutting-edge VTOL (Vertical Takeoff and Landing) drone designed for versatile applications. Weather-proof and equipped with a precision navigation system, it excels in diverse environments. Its extended flight duration and energy-efficient propulsion make it a reliable choice for various missions. The VTOL's versatile payload capacity, including medical supplies or surveillance equipment, ensures adaptability to dynamic needs. With rapid vertical takeoff capabilities, it swiftly responds to emergencies. The autonomous flight control system enhances operational efficiency, while its weather resilience extends its utility in challenging conditions. Whether delivering critical supplies or conducting surveillance, Vektas' VTOL drone represents a leap forward in aerial technology, promising reliability, adaptability, and efficiency across a spectrum of applications."
    }

    const features = [
        {
            image: require("../../../assets/images/icons/DISASTER-01.png"),
            title: "Swift VTOL Operations"
        },
        {
            image: require("../../../assets/images/icons/DISASTER-02.png"),
            title: "AI-Enhanced Locating"
        },
        {
            image: require("../../../assets/images/icons/DISASTER-03.png"),
            title: "Precision delivery Capability"
        },
        {
            image: require("../../../assets/images/icons/DISASTER-04.png"),
            title: "360-degree visibility"
        },
        {
            image: require("../../../assets/images/icons/DISASTER-05.png"),
            title: "robust propeller efficiency"
        },
        {
            image: require("../../../assets/images/icons/DISASTER-06.png"),
            title: "long flight endurance"
        },
    ]

    const app = [
        {
            image: require("../../../assets/images/Emergency.png"),
            tittle: "Emergency Response",
            paragraph: "With our propellers, drones serve as valuable tools for emergency response teams, swiftly deploying to disaster-stricken areas to assess damage, deliver supplies, and conduct search and rescue operations, aiding in disaster management and humanitarian efforts."
        },
        {
            image: require("../../../assets/images/AERIAL SURVEILANCE.jpg"),
            tittle: "Aerial Surveillance",
            paragraph: "Drones equipped with high-resolution cameras and sensors can be deployed for rapid aerial surveillance after natural disasters, providing real-time imagery to aid in search and rescue efforts, damage assessment, and resource allocation."
        },
        {
            image: require("../../../assets/images/DAMAGE ASSESSMENT.jpg"),
            tittle: "Damage Assessment and Mapping",
            paragraph: "Drones equipped with LiDAR (Light Detection and Ranging) and thermal imaging technology can quickly assess the extent of damage to infrastructure, buildings, and terrain, allowing emergency responders to prioritize areas for assistance and reconstruction efforts accurately."
        }

    ]
    return (
        <>
            <Banner imageSrc={data.image} heading={data.heading} paragraph={data.paragraph} />
            <Details tittle={details.tittle} paragraph={details.paragraph} />
            <Features data={features} />
            <Application data={app} />
            <GetMe />
        </>
    )
}
