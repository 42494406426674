import React from 'react';
import Banner from '../../includes/products/Banner';
import Details from '../../includes/products/Details';
import Features from '../../includes/products/Features';
import Application from '../../includes/products/Application';
import GetMe from '../GetMe/GetMe';


export default function Swarm() {
    const data = {
        image: require("../../../assets/images/Slides/swarm.jpg"),
        heading: "SWARM",
        paragraph: "SWARM addresses the growing demand for efficient drone coordination in various industries, enhancing tasks such as surveillance, agriculture, and logistics through synchronized autonomous swarm technology."
    };

    const details = {

        tittle: "OVERVIEW",
        paragraph: "Vektas Swarm, a cutting-edge drone technology, boasts smart navigation algorithms, ensuring precise and planned movements within a remarkable 2-meter range. With a formidable fleet of 110 drones, Vektas Swarm is designed for long-duration operations, offering an impressive operational endurance of up to 1 hour. Its advanced autonomy is further fortified by quick recovery mechanisms, enhancing its resilience in the face of unforeseen challenges. This feature-rich swarm system enables efficient and dynamic collaboration among drones, allowing them to seamlessly work together on complex tasks. Vektas Swarm stands at the forefront of autonomous drone technology, embodying a fusion of intelligence, reliability, and adaptability for diverse applications across industries, from surveillance to agriculture and beyond."
    }

    const features = [
        {
            image: require("../../../assets/images/icons/Swarm-icons1.png"),
            title: "Encrypted communication channels"
        },
        {
            image: require("../../../assets/images/icons/Swarm-icons2.png"),
            title: "Advanced analytics integration"
        },
        {
            image: require("../../../assets/images/icons/Swarm-icons3.png"),
            title: "Long Duration Operations"
        },
        {
            image: require("../../../assets/images/icons/Swarm-icons4.png"),
            title: "Smart navigation algorithms"
        },
        {
            image: require("../../../assets/images/icons/Swarm-icons5.png"),
            title: "Quick recovery mechanisms"
        },
        {
            image: require("../../../assets/images/icons/Swarm-icons6.png"),
            title: "Seamless swarm interaction"
        },
    ]

    const app = [
        {
            image: require("../../../assets/images/swarm-app3.jpg"),
            tittle: "Event Logistics Management",
            paragraph: "Vektas Swarm streamlines event logistics by providing efficient, automated delivery services. Its coordinated drone fleet optimizes the transportation of goods and equipment, ensuring timely and secure deliveries, revolutionizing event management processes."
        },
        {
            image: require("../../../assets/images/swarm-app4.jpg"),
            tittle: "Surveillance and Security",
            paragraph: "Vektas Swarm ensures robust surveillance and security solutions, leveraging its smart navigation algorithms and large drone fleet to monitor vast areas, enhance situational awareness, and respond swiftly to potential threats."
        },
        {
            image: require("../../../assets/images/swarm-app2.jpg"),
            tittle: "Aerial Spectacles",
            paragraph: " Vektas Swarm elevates airshows with mesmerizing aerial displays, orchestrating intricate formations and synchronized movements, captivating audiences with dynamic and precisely coordinated drone performances."
        }
    ]

    return (
        <>
            <Banner imageSrc={data.image} heading={data.heading} paragraph={data.paragraph} />
            <Details tittle={details.tittle} paragraph={details.paragraph} />
            <Features data={features} />
            <Application data={app} />
            <GetMe />
        </>
    );
}
