import React, { useState } from "react";

// import { Map, TileLayer } from "react-leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import osm from "./osm-providers";
import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet"; // Import leaflet library
import locationIcon from "../../../assets/images/icons/map.png";


const BasicMap = () => {
  const [center, setCenter] = useState({ lat: 8.56721287363151, lng: 76.87176001300976 });
  const ZOOM_LEVEL = 13;
  const mapRef = useRef();

  // Create a custom icon for the location marker
  const locationMarkerIcon = L.icon({
    iconUrl: locationIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  return (
    <>

      <div className="row">
        <div className="col text-center">
          <div className="col">
            <MapContainer center={center} zoom={ZOOM_LEVEL} ref={mapRef}>
              <TileLayer
                url={osm.maptiler.url}
                attribution={osm.maptiler.attribution}
              />
              {/* Add Marker for the location */}
              <Marker position={center} icon={locationMarkerIcon}>
                <Popup>VEKTAS TECHNOLOGIES </Popup>
              </Marker>
            </MapContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicMap;