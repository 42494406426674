import React from 'react';
import Banner from '../../includes/products/Banner';
import Details from '../../includes/products/Details';
import Features from '../../includes/products/Features';
import Application from '../../includes/products/Application';
import GetMe from '../GetMe/GetMe';

export default function WindTunnel() {
    const data = {
        image: require("../../../assets/images/wind-mgt.jpg"),
        heading: "Wind Tunnel",
        paragraph: "Wind tunnels are essential for aerodynamic testing across industries, ensuring optimal design in aviation, automotive engineering, sports equipment, and architectural structures."
    };

    const details = {

        tittle: "OVERVIEW",
        paragraph: "Our wind tunnel system sets the benchmark in aerodynamic research with a cutting-edge design. With a remarkable live feed speed of 1 GB/sec, researchers can access real-time data for unparalleled insights. The system boasts an impressive maximum speed of 100 m/sec, providing an extensive range for testing various objects, from aircraft to sports equipment. Notably, our wind tunnel minimizes turbulence to an exceptional 0.2%, ensuring precise and reliable results. The technology features a sophisticated control system for precision and a user-friendly interface for seamless operation. Its modular design allows customization, adapting to the diverse needs of different experiments. This wind tunnel is at the forefront of aerodynamic testing, offering a combination of speed, accuracy, and adaptability that empowers researchers across industries."
    }

    const features = [
        {
            image: require("../../../assets/images/icons/wind-tunnel-icon1.png"),
            title: "User-friendly Interface"
        },
        {
            image: require("../../../assets/images/icons/wind-tunnel-icon2.png"),
            title: "High-speed Airflow Generation"
        },
        {
            image: require("../../../assets/images/icons/wind-tunnel-icon3.png"),
            title: "Customizable Modular Design"
        },
        {
            image: require("../../../assets/images/icons/wind-tunnel-icon4.png"),
            title: "Precision Control System"
        },
        {
            image: require("../../../assets/images/icons/wind-tunnel-icon5.png"),
            title: "Advanced Aerodynamic Simulation"
        },
        {
            image: require("../../../assets/images/icons/wind-tunnel-icon6.png"),
            title: "Real-time Data Analytics"
        },
    ]

    const app = [

        {
            image: require("../../../assets/images/wind-tunnel-app2.jpg"),
            tittle: "Automotive Industry",
            paragraph: "Drive innovation in automotive design by utilizing our wind tunnel to refine vehicle aerodynamics, reducing drag, and improving fuel efficiency for a sustainable and competitive edge."
        },
        {
            image: require("../../../assets/images/wind-tunnel-app1.jpg"),
            tittle: "Renewable Energy",
            paragraph: "Transform wind turbine technology with our wind tunnel, facilitating research to maximize energy output and efficiency. Harness the power of the wind for sustainable and impactful wind energy solutions."
        },
        {
            image: require("../../../assets/images/wind-tunnel-app3.jpg"),
            tittle: "AEROSPACE ENGINEERING",
            paragraph: "Transform wind turbine technology with our wind tunnel, facilitating research to maximize energy output and efficiency. Harness the power of the wind for sustainable and impactful wind energy solutions."
        }
    ]

    return (
        <>
            <Banner imageSrc={data.image} heading={data.heading} paragraph={data.paragraph} />
            <Details tittle={details.tittle} paragraph={details.paragraph} />
            <Features data={features} />
            <Application data={app} />
            <GetMe />
        </>
    );
}
