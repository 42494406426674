import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function PrimaryButton({ text, to }) {
    const [activeNavItem, setActiveNavItem] = useState(null);

    const handleNavItemClicked = () => {
        setActiveNavItem(0);
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        const pathname = window.location.pathname;
        const navItems = ['/', '/explore', '/product', '/service', '/design', '/contact'];
        const activeIndex = navItems.indexOf(pathname);
        setActiveNavItem(activeIndex !== -1 ? activeIndex : null);
    }, []);

    return (
        <Button to={to} onClick={handleNavItemClicked} >{text}</Button>
    );
}

const Button = styled(Link)`
    color: #fff;
    display: inline-block;
    padding: 17px 18px;
    background: linear-gradient(135deg, rgba(47, 83, 164, 1), rgba(18, 19, 62, 1));
    clip-path: polygon(15px 0, calc(100% - 0px) 0, 100% 15px, 100% calc(100% - 15px), calc(100% - 15px) 100%, 15px 100%, 0 calc(100% - 0px), 0 15px);
    background-repeat: no-repeat;
    font-size: 20px;
    text-transform: uppercase;
    font-family: "kenexy";
    cursor: pointer;
    transition: all 0.5s ease;
    text-decoration: none;

    &:hover {
        background: linear-gradient(45deg, rgba(47, 83, 164, 1), rgba(18, 19, 62, 1));
    }

    @media all and (max-width: 1280px) {
        font-size: 16px;
    }

    @media all and (max-width: 980px) {
        font-size: 12px;
        padding: 14px 16px;
    }
`;
