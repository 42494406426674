import React from 'react'
import "../../assets/css/style.css"
import styled from 'styled-components';
import ReactPlayer from 'react-player'
import demo from "../../assets/images/demo.mp4"

import bg from "../../assets/images/explore-bg.png"
import vision from "../../assets/images/icons/vision.png"
import mission from "../../assets/images/icons/mission.png"
import values from "../../assets/images/icons/value.png"
import Message from './contact/Message';
import WSPGallery from '../includes/Gallery/WSPGallery';
import GetMe from './GetMe/GetMe';


export default function ExploreUs() {

    const galleryImages = [
        {
            img: require("../../assets/images/gallery/gallery1.jpg"),
        },
        {
            img: require("../../assets/images/gallery/gallery2.jpg"),
        },
        {
            img: require("../../assets/images/gallery/gallery3.jpg"),
        },
        {
            img: require("../../assets/images/gallery/gallery4.jpg"),
        },
        {
            img: require("../../assets/images/gallery/gallery5.jpg"),
        },
        {
            img: require("../../assets/images/gallery/gallery6.jpg"),
        },
        {
            img: require("../../assets/images/gallery/gallery7.jpg"),
        },
        {
            img: require("../../assets/images/gallery/gallery8.jpg"),
        }, {
            img: require("../../assets/images/gallery/gallery9.jpg"),
        }, {
            img: require("../../assets/images/gallery/gallery10.jpg"),
        }, {
            img: require("../../assets/images/gallery/gallery11.jpg"),
        }, {
            img: require("../../assets/images/gallery/gallery12.jpg"),
        }, {
            img: require("../../assets/images/gallery/gallery13.jpg"),
        }, {
            img: require("../../assets/images/gallery/gallery14.jpg"),
        },
        {
            img: require("../../assets/images/gallery/gallery15.jpeg"),
        },
        {
            img: require("../../assets/images/gallery/gallery16.jpeg"),
        },
        {
            img: require("../../assets/images/gallery/gallery17.jpeg"),
        },
    ]

    return (
        <>
            <MainContainer>
                <BgContainer></BgContainer>
                <Wrapper className='wrapper'>
                    <WhoWeAreContainer>
                        <Tittle className='gradient'>WHO WE ARE</Tittle>
                        <Description>Vektas Technologies Private Limited is a trailblazing technology company with a clear vision for the future. We specialize in pioneering solutions within the realm of unmanned aerial systems, fueled by a deep commitment to innovation and excellence. As a team of forward- thinkers, we embrace challenges as opportunities, leveraging our expertise to develop cutting-edge technologies that redefine industry standards and empower our clients. At Vektas, our identity is characterized by a passion for pushing boundaries, a dedication to quality, and a collective spirit that thrives on transforming ideas into impactful realities.</Description>
                        <Description>As architects of progress, our mission is to be at the forefront of transformative technology. We envision a world where our innovations not only address contemporary challenges but also inspire positive change on a global scale. Rooted in a culture of collaboration, adaptability, and integrity, Vektas Technologies is more than a technology company; we are catalysts for a connected, sustainable, and innovative future.</Description>

                        <VideoPlayer className='wrapper'>
                            <ReactPlayer
                                url="https://youtu.be/_C3Owm2gXfw?si=mvuxIBLQwJGABm-R"
                                width="100%"
                                height="100%"
                                controls={true}
                            />
                        </VideoPlayer>
                    </WhoWeAreContainer>

                    <MissionBox>
                        <Ul>
                            <Li>
                                <TopContainer>
                                    <Img src={mission} alt='Image' />
                                </TopContainer>
                                <LiTittle>MISSION</LiTittle>
                                <LiDescription>To lead the way in technological innovation, providing transformative solutions that address
                                    real-world challenges and elevate industries to new heights. </LiDescription>
                            </Li>
                            <Li>
                                <TopContainer>
                                    <Img src={vision} alt='Image' />
                                </TopContainer>
                                <LiTittle>VISION</LiTittle>
                                <LiDescription>To be a global force driving positive change, shaping a future where our advancements
                                    redefine possibilities and create a more connected, sustainable world. </LiDescription>
                            </Li>
                            <Li>
                                <TopContainer>
                                    <Img src={values} alt='Image' />
                                </TopContainer>
                                <LiTittle>VALUE</LiTittle>
                                <LiDescription>At Vektas Technologies, our core values of innovation, excellence, sustainability, integrity,
                                    and collaboration drive our commitment to transformative technology. </LiDescription>
                            </Li>
                        </Ul>
                    </MissionBox>
                </Wrapper>

                <Message />

                <WSPGallery galleryImages={galleryImages} />

            </MainContainer>

            <GetMe />
        </>
    )
}

const MainContainer = styled.div`
    padding-bottom: 60px;
    animation: drop 1.5s ease;
`;

const Wrapper = styled.div`

`;

const BgContainer = styled.div`
  background-image: url(${bg});
    filter: brightness(70%);
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 750px;
    @media all and (max-width: 1180px) {
        height: 500px;
    }
    @media all and (max-width: 640px) {
        height: 385px;
    }
`;

const WhoWeAreContainer = styled.div`
    /* width: 70%; */
    background: #fff;
    padding: 80px 60px ;
    position: relative;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    top: -160px;
    height: 1130px;
    @media all and (max-width: 1280px) {
        height: 1160px;
        
    }
    @media all and (max-width: 1180px) {
        height: 950px;
        padding: 60px 40px 0;
        
    }
    @media all and (max-width: 980px) {
        height: 865px;

    }
    @media all and (max-width: 768px) {
        height: 800px;

    }
    @media all and (max-width: 640px) {
        padding: 40px 30px 0;
        height: 945px;
    }
    @media all and (max-width: 530px) {
        height: 880px;
    }
    /* @media all and (max-width: 480px) {
        height: 860px;
    } */
    @media all and (max-width: 430px) {
        height: 885px;
    }
    @media all and (max-width: 400px) {
        height: 915px;
    }
`;

const Tittle = styled.h4`
    font-size: 54px;
    text-align: center;
    font-family: 'kenexy';
    @media all and (max-width: 1180px) {
        font-size: 48px;
    }
    @media all and (max-width: 768px) {
        font-size: 38px;
    }
     @media all and (max-width: 480px) {
        font-size: 25px;
    }
`;

const Description = styled.p`
    font-size: 20px;
    font-family: 'montserrat';
    color: #000;
    text-align: justify;
    margin-top: 40px;
     @media all and (max-width: 1180px) {
        font-size: 18px;
    }
     @media all and (max-width: 768px) {
        font-size: 15px;
    }
      @media all and (max-width: 480px) {
        margin-top: 20px;
    }
`;
const VideoPlayer = styled.div`
    margin-top: 50px;
    overflow: hidden;
    width: 100%;
    height: 700px;
    @media all and (max-width: 1180px) {
        height: 550px;
    }
    @media all and (max-width: 980px) {
        height: 450px;
    }
     @media all and (max-width: 800px) {
        height: 400px;
    }
    @media all and (max-width: 768px) {
        height: 350px;
    }
    @media all and (max-width: 640px) {
        height: 300px;
    }
      @media all and (max-width: 530px) {
        height: 240px;
    }
    @media all and (max-width: 480px) {
        height: 210px;
    }
    @media all and (max-width: 430px) {
        height: 185px;
    }
      @media all and (max-width: 380px) {
        height: 160px;
    }
`;

const MissionBox = styled.div`
    padding: 60px 0;
    position: relative;
    @media all and (max-width: 1180px) {
        padding: 40px 0 0
    }
      @media all and (max-width: 768px) {
        padding: 40px 0;
    }
     @media all and (max-width: 640px) {
       top: -140px;
    }
    @media all and (max-width: 530px) {
       top: -10px;
    }
`;

const Ul = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    grid-gap: 50px;
    margin-top: 80px;
    @media all and (max-width: 1180px) {
        grid-gap: 20px;
    }
     @media all and (max-width: 768px) {
        flex-wrap: wrap;
        width: 72%;
        margin: 0 auto;
        grid-gap: 40px;

    }
`;

const Li = styled.li`
    list-style: none;
    margin:  0 auto;
    border: 1px solid rgba(47, 83, 164, 1);  
    padding: 40px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        background: linear-gradient(to bottom, rgba(47, 83, 164, 1), rgba(18, 19, 62, 1));
        transition: all 0.3s ease;
    }
    @media all and (max-width: 1180px) {
        padding: 20px;
        min-height: 353px;
    }
    @media all and (max-width: 768px) {
        padding: 20px;
        min-height: 285px;
    }

`;
const LiTittle = styled.h4`
    font-size: 48px;
    font-family: 'kenexy';
    text-align: center;
    width: 98%;
    margin: 20px auto 0;
    ${Li}:hover & {
        color: #fff;
    }
    @media all and (max-width: 1180px) {
        font-size: 26px;
    }
`;

const LiDescription = styled.p`
    font-size: 18px;
    margin-top: 15px;
    color: #000;
    font-family: 600;
    text-align: center;
    font-family: 'montserrat';
    ${Li}:hover & {
        color: #fff;
    }
    @media all and (max-width: 1180px) {
        font-size: 16px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const TopContainer = styled.div`
    margin: 0 auto;
    width: 100px;
    height: 100px;
    @media all and (max-width: 1180px) {
     width: 64px;
    height: 64px;
    }
       @media all and (max-width: 480px) {
     width: 44px;
    height: 44px;
    }
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    ${Li}:hover & {
        filter: invert(1);
    }
`;

