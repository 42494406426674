import React from 'react'
import styled from 'styled-components';
import '../../../assets/css/style.css';

import Innovation from '../../../assets/images/icons/why-us-innovation.png';
import Quality from '../../../assets/images/icons/why-us-quality.png';
import Service from '../../../assets/images/icons/why-us-service.png';
import Trust from '../../../assets/images/icons/why-us-versality.png';

export default function WhyUs() {
    return (
        <>
            <MainContainer>
                <div className='wrapper'>
                    <Tittle className='gradient'>WHY US</Tittle>
                    <SubTittle>Opt for exceptional drone solutions with us. Our commitment to innovation ensures a reliable
                        and unparalleled unmanned aviation experience.</SubTittle>
                    <Ul>
                        <Li>
                            <TopContainer>
                                <Img src={Innovation} alt='Image' />
                            </TopContainer>
                            <LiTittle>INNOVATION</LiTittle>
                        </Li>
                        <Li>
                            <TopContainer>
                                <Img src={Quality} alt='Image' />
                            </TopContainer>
                            <LiTittle>QUALITY</LiTittle>
                        </Li>
                        <Li>
                            <TopContainer>
                                <Img src={Service} alt='Image' />
                            </TopContainer>
                            <LiTittle>END TO END SERVICE</LiTittle>
                        </Li>
                        <Li>
                            <TopContainer>
                                <Img src={Trust} alt='Image' />
                            </TopContainer>
                            <LiTittle>VERSALITY</LiTittle>
                        </Li>
                    </Ul>
                </div>
            </MainContainer>
        </>
    )
}

const MainContainer = styled.div`
    padding: 60px 0;
    background: #fff;
    position: relative;
    z-index: 1000;
`;
const Tittle = styled.h4`
    font-weight: 400;
    font-family: "kenexy";
    font-size: 70px;
    text-align: center;
    @media all and (max-width: 1280px) {
        font-size: 50px;
    }
      @media all and (max-width: 980px) {
        font-size: 44px;
    }
    @media all and (max-width: 480px) {
        font-size: 34px;
    }
`;
const SubTittle = styled.p`
    font-size: 22px;
    text-align: center;
    font-family: 'montserrat';
    color: #000;
    width: 78%;
    margin:  30px auto 0;
    @media all and (max-width: 1280px) {
        font-size: 20px;
    }
    @media all and (max-width: 980px) {
        font-size: 18px;
    }
    @media all and (max-width: 768px) {
        font-size: 15px;
    }
`;

const Ul = styled.ul`
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    grid-gap: 10px;
    margin-top: 70px;
    @media all and (max-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
`;

const Li = styled.li`
    list-style: none;
    margin:  0 auto;
`;
const LiTittle = styled.p`
    color: #000;
    font-size: 18px;
    font-family: 'kenexy';
    text-align: center;
    /* width: 98%; */
    margin: 20px auto 0;
    @media all and (max-width: 1280px) {
        font-size: 16px;
        width: 100%;
    }
    @media all and (max-width: 768px) {
        font-size: 12px;
    }
    @media all and (max-width: 768px) {
        font-size: 10px;
    }
`;
const TopContainer = styled.div`
margin: 0 auto;
    width: 122px;
    height: 122px;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid rgba(47, 83, 164, 1);
    &:hover {
        background: linear-gradient(to bottom, rgba(47, 83, 164, 1), rgba(18, 19, 62, 1));
        transition: all 0.3s ease;
    }
    @media all and (max-width: 980px) {
        width: 84px;
        height: 84px;
    }
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    filter: invert(1);
    ${TopContainer}:hover & {
        filter: invert(0);
    }
`;
