import React from 'react';
import Scroll from './Scroll';
import "../../../assets/css/style.css";
import styled from 'styled-components';

import propeller from '../../../assets/images/Slides/product-propeller.jpg';
import vtol from '../../../assets/images/Slides/product-vtol.jpg';
import disaster from '../../../assets/images/disaster-slide.jpg';

import wind from '../../../assets/images/Slides/wind-tunnel.jpg';
import tunnel from '../../../assets/images/wind-tunnel-home.jpg';


import fight from '../../../assets/images/Slides/fire.jpg';
import survilance from '../../../assets/images/Slides/survilance.jpg';
import agriculture from '../../../assets/images/Slides/agricultural.jpg';
import medical from '../../../assets/images/Slides/medical.jpg';
import fabrication from '../../../assets/images/Slides/fabrication.jpg';


import swarm from '../../../assets/images/Slides/swarm.jpg';
import flood from '../../../assets/images/new1.jpg';

import parachute from '../../../assets/images/Slides/parachute.jpg';
import three from '../../../assets/images/Slides/3d.jpg';





export default function StickySlide() {


    const design = [
        {
            image: parachute,
            title: "PARACHUTE DESIGN",
            paragraph: "Design consultations",
            link: () => {
                return "/design";
            }
        },
        {
            image: three,
            title: "ENGINEERING DESIGN",
            paragraph: "Design consultations",
            link: () => {
                return "/design";
            }
        }
        ,
        {
            image: wind,
            title: "WIND TUNNEL MODEL DESIGN",
            paragraph: "Design consultations",
            link: () => {
                return "/design";
            }
        },
        {
            image: fabrication,
            title: "INDUSTRIAL AUTOMATION",
            paragraph: "Design consultations",
            link: () => {
                return "/design";
            }
        }

    ];

    const product = [
        {
            image: propeller,
            title: "PROPELLERS",
            paragraph: "products",
            link: () => {
                return "/product/propellers";
            }
        },
        {
            image: disaster,
            title: "DISASTER MANAGEMENT",
            paragraph: "products",
            link: () => {
                return "/product/disaster-management";
            }
        },
        {
            image: vtol,
            title: "VTOL DRONES",
            paragraph: "products",
            link: () => {
                return "/product/vtol-drone";
            }
        },
        {
            image: tunnel,
            title: "WIND TUNNEL",
            paragraph: "products",
            link: () => {
                return "/product/wind-tunnel";
            }
        }
        ,
        {
            image: swarm,
            title: "SWARM",
            paragraph: "products",
            link: () => {
                return "/product/swarm";
            }
        }
    ];

    const service = [
        {
            image: fight,
            title: "FIRE FIGHTING DRONE",
            paragraph: "services",
            link: () => {
                return "/service";
            }
        },
        {
            image: flood,
            title: "FLOOD RESCUE DRONE",
            paragraph: "services",
            link: () => {
                return "/service";
            }
        },
        {
            image: survilance,
            title: "SURVEILLANCE DRONE",
            paragraph: "services",
            link: () => {
                return "/service";
            }
        },
        {
            image: agriculture,
            title: "AGRICULTURAL DRONE",
            paragraph: "services",
            link: () => {
                return "/service";
            }
        },
        {
            image: medical,
            title: "DELIVERY DRONE",
            paragraph: "services",
            link: () => {
                return "/service";
            }
        },
    ];


    return (
        <>
            <MainContainer>
                <Scroll data={product} />
            </MainContainer>
            <MainContainer>
                <Scroll data={service} />
            </MainContainer>

            <MainContainer>
                <Scroll data={design} />
            </MainContainer>


        </>

    );
}
const MainContainer = styled.div`
    animation: drop 1.5s ease; 
    position: -webkit-sticky; 
    position: sticky; 
    top: 80px;
    height: 100vh;
    z-index: 6;
    overflow: hidden;
    /* Safari-specific adjustments */
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        position: -webkit-sticky;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    @media all and (max-width: 1280px) {
        height: 85vh;
    }
    @media all and (max-width: 1080px) {
        /* display: none; */
        height: 71vh;
        top: 117px;
    }
    @media all and (max-width: 980px) {
        height: 65vh;
    }
    @media all and (max-width: 768px) {
        height: 50vh;
        top: 100px;
    }
    @media all and (max-width: 640px) {
        height: 42vh;
        position: static;
    }
    @media all and (max-width: 530px) {
        height: 35vh;
    }
    @media all and (max-width: 480px) {
        height: 32vh;
    }
    @media all and (max-width: 430px) {
        height: 34vh;
    }
  
`; 