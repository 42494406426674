import React from 'react';
import '../../../assets/css/style.css'
import styled from 'styled-components';

export default function Banner({ imageSrc, heading, paragraph }) {
    return (
        <MainContainer>
            <Container>
                <ImgContainer image={imageSrc}>
                    <Content className='wrapper'>
                        <H1>{heading}</H1>
                        <Paragraph>{paragraph}</Paragraph>
                    </Content>
                </ImgContainer>
            </Container>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    padding: 100px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: drop 1.5s ease;
   
`;

const Container = styled.div`
    width: 94%;
    height: 780px;

    @media all and (max-width: 1180px) {
     height: 560px;
    }
    @media all and (max-width: 768px) {
     height: 400px;
    }
    @media all and (max-width: 480px) {
     height: 320px;
    }
`;
const ImgContainer = styled.div`
    width: 100%;
    min-height: 100%; /* Adjust as needed */
    border-radius: 60px;
    background-image: url(${props => props.image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @media all and (max-width: 480px) {
        border-radius: 30px;
    }
`;
const Content = styled.div`
    padding: 30px;
    position: absolute;
    top: 60%;
    z-index: 100;
    @media all and (max-width: 768px) {
        top: 40%;
    }
    @media all and (max-width: 480px) {
        top: 30%;
        width: 100%;
    }
    @media all and (max-width: 400px) {
        top: 20%;
        width: 100%;
    }
 
`;

const H1 = styled.h1`
    font-size: 44px;
    font-family: 'kenexy';
    color: #fff;
    z-index: 1000;
    text-transform: uppercase;
    @media all and (max-width: 1180px) {
        font-size: 36px;
    }
    @media all and (max-width: 640px) {
        font-size: 30px;
    }
    @media all and (max-width: 640px) {
        font-size: 22px;
    }
`;

const Paragraph = styled.p`
    font-size: 22px;
    font-family: 'montserrat';
    margin-top: 10px;
    color: #fff;
    @media all and (max-width: 1180px) {
        font-size: 18px;
    }
    @media all and (max-width: 768px) {
        font-size: 15px;
    }
`;