import React from 'react';
import Spotlight from './Home/Spotlight';
import WhoWeAre from './Home/WhoWeAre';
import WhyUs from './Home/WhyUs';
import Events from './Home/Events';
import StickySlide from './Home/StickySlide';
import GetMe from './GetMe/GetMe';


export default function Home() {

    return (
        <>
            <Spotlight />
            <WhoWeAre />
            <StickySlide />
            <WhyUs />
            <Events />
            <GetMe />
        </>
    );
}
