import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';

import Slide1 from '../../../assets/images/slide.jpg'
import Slide2 from '../../../assets/images/huddle.jpg'
import Slide3 from '../../../assets/images/event3.png'

import Certificate from '../../../assets/images/certificate.png'


export default function Events() {
    const settings = {
        autoplay: true,
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <>
            <MainContainer>
                <div className='wrapper'>
                    <Tittle >
                        EVENTS
                    </Tittle>
                    <Slide>

                        <Ul>
                            <Slider {...settings}>
                                <Li>
                                    <TopContainer>
                                        <Img src={Slide1} alt='Image' />
                                    </TopContainer>
                                    <LiTittle>Vektas Technologies hosted a captivating workshop and airshow at Marian Engineering College's tech fest, showcasing advanced technology and fostering innovation.</LiTittle>
                                </Li>
                                <Li>
                                    <TopContainer>
                                        <Img src={Slide2} alt='Image' />
                                    </TopContainer>
                                    <LiTittle>Vektas leaves a lasting impression at Huddle Global 2023, proudly showcasing innovative offerings at the vibrant Kerala Startup Mission stall!</LiTittle>
                                </Li>
                                <Li>
                                    <TopContainer>
                                        <Img src={Slide3} alt='Image' />
                                    </TopContainer>
                                    <LiTittle> Vektas Technology wasn't just participating in the Bihar Innovation Challenges this year (2023), we made it all the way to the finals!</LiTittle>
                                </Li>
                            </Slider>

                        </Ul>

                    </Slide>

                    <Certification>
                        <Tittle className='gradient'>CERTIFICATION</Tittle>
                        <Container>
                            <CIMG src={Certificate} alt='Image' />
                        </Container>
                    </Certification>

                </div>
            </MainContainer>
        </>
    )
}

const MainContainer = styled.div`
    padding: 70px;
    background: linear-gradient(
        180deg,
        rgba(47, 83, 164, 1),
        rgba(255, 255, 255, 1)
    );
    position: relative;
    z-index: 1000;

    @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
        position: -webkit-sticky;
    }
    }
    @media all and (max-width: 480px) {
        padding: 50px 20px;

    }
        
`;
const Tittle = styled.h4`
    font-size: 56px;
    font-family: 'kenexy';
    text-align: center;
    color: #fff;
    @media all and (max-width: 1280px) {
        font-size: 50px;
    }
    @media all and (max-width: 980px) {
        font-size: 44px;
    }
    @media all and (max-width: 768px) {
        font-size: 38px;
    }
     @media all and (max-width: 480px) {
        font-size: 30px;
    }
`;

const Slide = styled.div`
    margin-top: 60px;
`;

const Li = styled.li`
    list-style: none;
`;

const TopContainer = styled.div`
    border-radius: 20px;
    overflow: hidden;
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
`;

const Ul = styled.ul`
  
`;

const LiTittle = styled.h4`
    font-size: 22px;
    text-align: center;
    font-family: 'montserrat';
    color: #000;
    width: 76%;
    margin: 40px auto 0;
    line-height: 1.5;
    @media all and (max-width: 1280px) {
        font-size: 18px;
    }
    @media all and (max-width: 1280px) {
       width: 100%;
        font-size: 16px;

    }
`;

const Certification = styled.div`
    margin-top: 160px;
    @media all and (max-width: 980px) {
        margin-top: 100px;
    }
    @media all and (max-width: 768px) {
        margin-top: 60px;
    }
`;


const Container = styled.div`
    margin-top: 100px;
    overflow: hidden;
    @media all and (max-width: 1280px) {
         margin-top: 60px;
    }
`;

const CIMG = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
`;