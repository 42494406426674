import React from 'react'
import "../../../assets/css/style.css"
import styled from 'styled-components';

export default function Details({ tittle, paragraph }) {
    return (
        <>
            <MainContainer>
                <div className='wrapper'>
                    <Container>
                        <Tittle className='gradient'>{tittle}</Tittle>
                        <Paragraph>{paragraph}</Paragraph>
                    </Container>
                </div>
            </MainContainer>
        </>
    )
}

const MainContainer = styled.div`
    background: #fff;
    padding: 50px 0;
    animation: drop 1.5s ease;
    @media all and (max-width: 480px) {
    padding: 30px 0;
    }
`;

const Container = styled.div`
    text-align: center;
`;
const Tittle = styled.h4`
    font-size: 54px;
    @media all and (max-width: 1280px) {
        font-size: 44px;
    }
    @media all and (max-width: 1180px) {
        font-size: 34px;
    }
    @media all and (max-width: 480px) {
        font-size: 30px;
    }
    
`;
const Paragraph = styled.p`
    font-size: 22px;
    margin-top: 20px;
    font-family: 'montserrat';
    text-align: justify;
    @media all and (max-width: 1280px) { 
        font-size: 18px;
    }
    @media all and (max-width: 1180px) {
        font-size: 16px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
