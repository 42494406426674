import React from 'react'
import GetMe from './contact/GetMe'

export default function Contact() {
    return (
        <>
            <GetMe />
        </>
    )
}
