import React from 'react';
import styled from 'styled-components'; // Add this line
import "../../assets/css/style.css"

import bg from '../../assets/images/design-bg.jpg';
import parachute from '../../assets/images/PARACHUTE DESIGN.png';
import threedy from '../../assets/images/engineering.jpg';
import fabrication from '../../assets/images/fabrications.png';
import tunnel from '../../assets/images/wind-tunnel .jpg';
import GetMe from './GetMe/GetMe';


export default function Design() {
    return (
        <>
            <MainContainer>
                <BgContainer></BgContainer>
                <WhoWeAreContainer>
                    <DesignContainer>
                        <Ul>
                            <Li className='wrapper'>
                                <Left>
                                    <ImgContainer><Img src={parachute} alt="Image" />
                                    </ImgContainer>
                                </Left>
                                <Right>
                                    <Details>
                                        <Tittle>PARACHUTE DESIGN</Tittle>
                                        <Description>
                                            Vektas Technologies pioneers drone safety with our advanced Parachute Deployment
                                            Systems. Engineered for reliability, these systems ensure controlled descents in
                                            emergencies, minimizing risks and preserving both drones and surroundings. Precision
                                            landings and swift resets make them essential for applications like search and rescue,
                                            surveillance, and cargo delivery.
                                        </Description>
                                    </Details>
                                </Right>
                            </Li>

                            <Li className='wrapper'>
                                <Right>
                                    <Details>
                                        <Tittle>engineering design </Tittle>
                                        <Description>
                                            Our team of experienced engineers is here to bring your ideas to life. Whether you're starting from scratch or refining existing designs, we provide tailored solutions to meet your specific needs. From concept development to detailed blueprints, we offer comprehensive support throughout the design process.
                                        </Description>
                                    </Details>
                                </Right>
                                <Left>
                                    <ImgContainer><Img src={threedy} alt="Image" />
                                    </ImgContainer>
                                </Left>
                            </Li>

                            <Li className='wrapper'>
                                <Left>
                                    <ImgContainer><Img src={tunnel} alt="Image" />
                                    </ImgContainer>
                                </Left>
                                <Right>
                                    <Details>
                                        <Tittle>WIND TUNNEL MODEL DESIGN</Tittle>
                                        <Description>
                                            The Wind Tunnel Model Design service offers precise and customized models tailored for aerodynamic testing. Using advanced software and engineering expertise, models are crafted to accurately simulate real-world conditions, enabling Vektas clients to optimize designs for maximum performance and efficiency.
                                        </Description>
                                    </Details>
                                </Right>
                            </Li>

                            <Li className='wrapper'>
                                <Right>
                                    <Details>
                                        <Tittle>industrial Automation</Tittle>
                                        <Description>
                                        We specialize in cutting-edge industrial automation technologies designed to optimize efficiency, enhance productivity, and streamline operations across various sectors. 
                                        With a focus on innovation and reliability, we empower businesses to automate repetitive tasks, improve safety standards, and maximize output while minimizing downtime. Whether you're in manufacturing, logistics, or any other industry, our tailored automation solutions are crafted to meet your unique needs and drive tangible results.
                                        </Description>
                                    </Details>
                                </Right>
                                <Left>
                                    <ImgContainer><Img src={fabrication} alt="Image" />
                                    </ImgContainer>
                                </Left>
                            </Li>
                        </Ul>
                    </DesignContainer>
                </WhoWeAreContainer>
            </MainContainer>
            <GetMe />
        </>
    );
}

const MainContainer = styled.div`
    padding-bottom: 60px;
    animation: drop 1.5s ease;

`;

const Wrapper = styled.div``;

const BgContainer = styled.div`
    background-image: url(${bg});
    filter: brightness(70%);
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 750px;

    @media all and (max-width: 1280px) {
    height: 650px;
        
    }
    @media all and (max-width: 1180px) {
        height: 580px;
    }
    @media all and (max-width: 768px) {
        height: 480px;
    }
    @media all and (max-width: 530px) {
        height: 440px;
    }
    @media all and (max-width: 430px) {
        height: 360px;
    }
`;

const WhoWeAreContainer = styled.div`
    /* width: 70%; */
    background: #fff;
    padding: 80px 60px 0;
    position: relative;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    top: -100px;
    @media all and (max-width: 640px) {
        padding: 40px 0px 0;
    }
    @media all and (max-width: 530px) {
    top: -46px;
        
    }
`;

const DesignContainer = styled.div`

`;

const Ul = styled.ul``;
const Li = styled.li`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 20px;
    justify-content: flex-start;
    margin-bottom: 80px;
    min-height: 460px;
    padding-bottom: 25px;
    border-bottom: 1px solid #AFAFAF;

    &:nth-child(even){
        grid-template-columns: 2fr 1fr;
        justify-content: flex-start;
        @media all and (max-width: 768px) {
            grid-template-columns: 2fr;
        }
    }

    @media all and (max-width: 1180px) {
        min-height: 420px;
    }

    @media all and (max-width: 980px) {
        min-height: 350px;
    }

    @media all and (max-width: 768px) {
        grid-template-columns: 2fr;
        padding-bottom: 35px;
    }
    @media all and (max-width: 530px) {
        margin-bottom: 30px;
        
    }
    
`;

const Details = styled.div`
    padding: 0 20px;
`;

const Left = styled.div`

    @media all and (max-width: 768px) {
        /* order: 1; */
    }`;

const Right = styled.div`
    border-radius: 16px;
    overflow: hidden;

    @media all and (max-width: 768px) {
        order: 2;
    }
`;

const ImgContainer = styled.div`
    overflow: hidden;
    min-height: 100%;
    border-radius: 25px;
    @media all and (max-width: 530px) {
        height: 280px;
    }
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 25px;
`;

const Tittle = styled.h4`
    font-size: 40px;
    /* text-align: center; */
    font-family: 'kenexy';
    text-transform: uppercase;
    @media all and (max-width: 1180px) {
    font-size: 30px;
    }
      @media all and (max-width: 1080px) {
    font-size: 24px;
    }
    @media all and (max-width: 480px) {
    font-size: 18px;
    }
`;

const Description = styled.p`
    font-size: 20px;
    font-family: 'montserrat';
    color: #000;
    text-align: justify;
    margin-top: 40px;
    @media all and (max-width: 1180px) {
        font-size: 18px;
    }
      @media all and (max-width: 980px) {
        margin-top: 16px;
        font-size: 16px;
    }
    @media all and (max-width: 480px) {
        font-size: 15px;
    }
`;
